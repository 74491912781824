@import '~theme/variables';

.content {
  padding-left: $space-xs;
  padding-right: $space-xs;

  @include breakpoint(sm) {
    padding-left: $space-l;
    padding-right: $space-l;
  }

  @include breakpoint(md) {
    padding-left: $space-xxl;
    padding-right: $space-xxl;

    &.isLeftAligned {
      padding-left: $space-xxl;
      padding-right: $space-xxxl;
    }
  }
}

.title {
  font-size: 7.9rem;
}

.footer {
  display: flex;
  gap: $space-s;
}
